import * as React from "react";

const PlayArrowLarge: React.FC = () => (
  <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.334 11.3669V28.6335C13.334 29.9502 14.784 30.7502 15.9007 30.0335L29.4673 21.4002C29.7048 21.2499 29.9005 21.0419 30.0361 20.7957C30.1716 20.5495 30.2427 20.273 30.2427 19.9919C30.2427 19.7108 30.1716 19.4343 30.0361 19.188C29.9005 18.9418 29.7048 18.7338 29.4673 18.5835L15.9007 9.96687C15.6493 9.80428 15.3587 9.71255 15.0595 9.70136C14.7603 9.69017 14.4637 9.75993 14.2008 9.9033C13.938 10.0467 13.7187 10.2583 13.5662 10.5159C13.4136 10.7735 13.3334 11.0675 13.334 11.3669V11.3669Z"
      fill="#fff"
    />
  </svg>
);

export default PlayArrowLarge;
