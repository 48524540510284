import "../../layouts/main.scss";
import closeBtn from "../../images/icons/close-white-large.svg";

declare global {
  interface Window {
    _wq: any;
  }
}

const loadDynamicScript = (callback = null) => {
  const existingScript = document.getElementById("ev1");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "//fast.wistia.com/assets/external/E-v1.js";
    script.setAttribute("async", "");
    script.id = "ev1";
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) {
        callback();
      }
    };
  }

  if (existingScript && callback) {
    callback();
  }
};

function removeClass(className) {
  let classElement = document.getElementsByClassName(className);
  while (classElement.length) {
    classElement[0].classList.remove(className);
  }
}

function addClass(idName) {
  let idElement = document.getElementById(idName);
  idElement.classList.add(idName);
}

function videoModal(video_id) {
  loadDynamicScript();

  let wrapper = document.createElement("div");
  wrapper.innerHTML = `
    <div class="wistia_embed wistia_async_${video_id}
        popover=true"
        style="width:0; height:0;">
    </div>
    `;

  document.body.appendChild(wrapper);

  let videoModal;

  if (document.getElementById("video__load") === null) {
    videoModal = document.createElement("div");
    videoModal.id = "video__load";
    videoModal.innerHTML = `
      <button id="wistia_close_btn" style="background: url(${closeBtn}) center" aria-label="Close Button"></button>
      <div id="loading__gif" class="loading__gif"></div>
    `;
    document.body.appendChild(videoModal);
  } else {
    videoModal = document.getElementById("video__load");
  }

  videoModal.classList.add("loading");

  window._wq = window._wq || [];
  window._wq.push({
    id: video_id,
    onHasData: function (video) {
      function clearVideo() {
        video.remove();
        video.popover.hide();
        addClass("loading__gif");
        document.querySelector("html").classList.remove("overflow--hidden");
        setTimeout(function () {
          removeClass("loading");
        }, 100);
      }

      function stopVideo() {
        clearVideo();
        this.removeEventListener("click", stopVideo);
        removeClass("loading");
      }

      window.addEventListener("keyup", function stopVideoOnEsc(evt) {
        if (evt.key === "Escape") {
          clearVideo();
          document
            .getElementsByClassName("wistia_placebo_close_button")[0]
            ?.removeEventListener("click", stopVideo);
          document
            .getElementById("video__load")
            ?.removeEventListener("click", stopVideo);
          window.removeEventListener("keyup", stopVideoOnEsc);
        }
      });
      document
        .getElementsByClassName("wistia_placebo_close_button")[0]
        ?.addEventListener("click", stopVideo);
      document
        .getElementById("video__load")
        ?.addEventListener("click", stopVideo);
    },
    onReady: function (video) {
      video.play();
      removeClass("loading__gif");

      document.querySelector("html").classList.add("overflow--hidden");
    },
  });
}

export default videoModal;
