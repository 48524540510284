import * as React from "react";
import PlayArrow from "../../SVGIcons/ArrowButtons/PlayArrow";
import PlayArrowLarge from "../../SVGIcons/ArrowButtons/PlayArrowLarge";
import loadVideo from "../../../assets/util-scripts/load-video";

import styles from "./VideoButton.module.scss";

type DataProps = {
  buttonColor: string;
  iconWhite: boolean;
  isClass: boolean;
  videoID: string;
  isDemo?: boolean;
};

const VideoButton: React.FC<DataProps> = ({
  buttonColor,
  iconWhite,
  isClass,
  isDemo,
  videoID,
}) => {
  const playButton = isClass ? <PlayArrow /> : <PlayArrowLarge />;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    loadVideo(videoID);
    if (typeof window !== "undefined") {
      if (isDemo && window.trackMixpanelEvent) {
        window.trackMixpanelEvent("user: Marketing Demo viewed");
      }
      const analyticsData = {
        video_name: videoID,
        initiator: "User",
        object: "Video",
        action: "Viewed",
        url: window.location.pathname,
        source_application: "Marketing",
      };

      if (window?.analytics?.track) {
        window.analytics.track("Video Button Clicked", analyticsData);
      }
    }
  };

  const videoBtnStyles = `${styles.videoButton} ${buttonColor} ${
    iconWhite ? styles.iconWhite : styles.iconBlue
  } ${isClass ? "classes" : ""}`;

  return (
    <button
      data-testid="button:VideoButton"
      onClick={handleClick}
      id={`video__${videoID}`}
      className={videoBtnStyles}
      type="button"
    >
      {playButton}
    </button>
  );
};

export default VideoButton;
